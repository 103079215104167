<template>
  <div class="fields-control">
    <v-text-field
      v-model="search"
      class="mb-n4"
      clearable
      placeholder="Search fields..."
      autocomplete="off" />
    <slot name="topAction" />
    <component
      :is="disabledDragList ? 'div' : 'draggable'"
      v-model="allDefaultFields"
      tag="div"
      :draggable="allowDraggable && !disabledDragList &&'.fields-control__draggable'"
      class="fields-control__wrapper">
      <div
        v-for="(field, index) in fields"
        :key="field.name"
        :class="'item-to-check d-flex align-center mb-6'.concat(' ', index > 1 ? 'fields-control__draggable' : '')">
        <v-icon
          v-if="!disabledDragList"
          :class="!allowDraggable && 'fields-control__hide-icon'"
          class="fields-control__drag-icon">
          mdi-drag
        </v-icon>
        <v-switch
          class="d-flex align-center switcher"
          color="blue"
          :disabled="isDisabledForToggle(field.name)"
          :label="field.name"
          hide-details
          :input-value="resultNames.includes(field.name)"
          @change="changeState(field)" />
        <div
          v-if="!productSpecificFields.length"
          class="lock-icon">
          <v-icon
            class="ml-2 pointer"
            @click="changeLockField(field)">
            {{ iconLock(field.permissions) }}
          </v-icon>
        </div>
      </div>
    </component>
    <div
      class="action-buttons"
      :class="{'py-0': oneRowActionButtons }">
      <v-btn
        v-if="productSpecificFields.length"
        :class="{'sub-regular': oneRowActionButtons }"
        outlined
        @click="setProductSpecificFields">
        {{ setButtonTitle('selectSpecificFields') }}
      </v-btn>
      <div
        class="action-buttons__block">
        <v-btn
          class="white--text"
          :class="{'sub-regular': oneRowActionButtons }"
          color="blue"
          elevation="0"
          @click="action('show')">
          {{ setButtonTitle('selectAll') }}
        </v-btn>
        <v-btn
          :class="{'sub-regular': oneRowActionButtons }"
          outlined
          @click="action('hide')">
          {{ setButtonTitle('deselectAll') }}
        </v-btn>
      </div>
      <div
        class="action-buttons__block">
        <v-btn
          v-if="showCancel"
          :class="{'sub-regular': oneRowActionButtons }"
          outlined
          @click="closeWindow">
          Cancel
        </v-btn>
        <v-btn
          v-if="showSave"
          class="white--text"
          :class="{'sub-regular': oneRowActionButtons }"
          color="blue"
          elevation="0"
          :disabled="disabledSaveButton"
          @click="save">
          {{ setButtonTitle('saveButton') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from 'vuedraggable';
import {
  mapGetters, mapState,
} from 'vuex';
import {
  cloneDeep, isEqual,
} from 'lodash';
export default {
  name: 'ProjectDetailControlFields',
  components: {
    draggable,
  },
  props: {
    statusLockedAllFields: {
      type: Boolean,
      default: false,
    },
    disabledDragList: {
      type: Boolean,
      default: false,
    },
    fieldsLocked: {
      type: Array,
      default: () => [],
    },
    defaultFields: {
      type: Array,
      default: () => [],
    },
    selectedFields: {
      type: Array,
      default: () => [],
    },
    showCancel: {
      type: Boolean,
      default: true,
    },
    showSave: {
      type: Boolean,
      default: true,
    },
    fieldsToHideToggle: {
      type: Array,
      default: () => [],
    },
    buttonTitles: {
      type: Object,
      default: () => ({
        saveButton: 'Save',
        selectAll: 'Show all',
        deselectAll: 'Hide all',
      }),
    },
    productSpecificFields: {
      type: Array,
      default: () => [],
    },
    draggableFields: {
      type: Boolean,
      default: false,
    },
    oneRowActionButtons: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: '',
      results: [],
      allDefaultFields: [],
      oldControlLockedFields: [],
    };
  },
  computed: {
    ...mapState(['role']), // role only for projects
    ...mapGetters('UserRoles', ['canDragAndDropColumnsInSchedule']),
    ...mapGetters('ProjectDetailsTableSchedule', ['mappingControlFields']),
    isLockedAllFields() {
      const { allDefaultFields, resultNames } = this;
      const filterResultByAllDefaultFields = allDefaultFields.filter(e => resultNames.includes(e.name));
      return filterResultByAllDefaultFields.every(e => e.permissions?.[0]?.edit === false);
    },
    filterLockedFields() {
      const { newAllDefaultFieldsLocked, oldAllDefaultFieldsLocked } = this;
      const sortOldLockedFieldByNew = oldAllDefaultFieldsLocked.sort((a, b) => newAllDefaultFieldsLocked.indexOf(a) - newAllDefaultFieldsLocked.indexOf(b));
      return isEqual(sortOldLockedFieldByNew, newAllDefaultFieldsLocked) ? null : newAllDefaultFieldsLocked;
    },
    newAllDefaultFieldsLocked() {
      return this.allDefaultFields.filter(e => e.permissions?.[0]?.edit === false)?.map(e => e.name) || [];
    },
    oldAllDefaultFieldsLocked() {
      return this.oldControlLockedFields.filter(e => e.permissions?.[0]?.edit === false)?.map(e => e.name) || [];
    },
    resultNames() {
      return this.results.map(e => e.name);
    },
    allDefaultFieldsNames() {
      return this.allDefaultFields.map(e => e.name);
    },
    allowDraggable() {
      const {
        canDragAndDropColumnsInSchedule,
        fields,
      } = this;
      return canDragAndDropColumnsInSchedule && fields.length;
    },
    fields() {
      const { search, allDefaultFields } = this;
      if (search) {
        return allDefaultFields.filter(field => field.name.toLowerCase().includes(search.toLowerCase()));
      }
      return allDefaultFields;
    },
    disabledSaveButton() {
      return this.requireSelection && !this.results.length;
    },
  },
  watch: {
    isLockedAllFields: {
      immediate: true,
      handler(val) {
        this.$emit('update:statusLockedAllFields', val);
      },
    },
    selectedFields: {
      handler(val) {
        this.results = this.mappingControlFields(val);
      },
      immediate: true,
    },
    resultNames: {
      handler(val) {
        this.$emit('changeSelected', val);
      },
      immediate: true,
    },
    defaultFields: {
      handler(val) {
        const items = this.mappingControlFields(val);
        this.oldControlLockedFields = cloneDeep(items);
        this.allDefaultFields = cloneDeep(items);
      },
      immediate: true,
    },
  },
  methods: {
    isDisabledForToggle(field) {
      if (!this.fieldsToHideToggle.length) {
        return false;
      }
      return this.fieldsToHideToggle.includes(field);
    },
    //the method invoking from parent component
    lockUnlockAllFields() {
      const { allDefaultFields, resultNames, isLockedAllFields } = this;
      const edit = isLockedAllFields;
      this.allDefaultFields = allDefaultFields.map(e => ({
        ...e,
        ...(resultNames.includes(e.name) && {
          permissions: [{
            type: 'editor', edit,
          }],
        }),
      }));
    },
    changeLockField(field) {
      const { name, permissions = [] } = field ?? {
      };
      const [item] = permissions ?? [];
      const { edit = true } = item ?? {
      };
      const newField = {
        ...field,
        permissions: [
          {
            type: 'editor',
            edit: !edit,
          },
        ],
      };
      this.allDefaultFields = this.allDefaultFields.map(mappedItem => {
        return mappedItem.name === name ? newField : mappedItem;
      });
    },
    iconLock(permissions = []) {
      const [item] = permissions ?? [];
      const { edit = true } = item ?? {
      };
      return edit ? 'mdi-lock-open' : 'mdi-lock';
    },
    setButtonTitle(type) {
      return this.buttonTitles?.[type];
    },
    setProductSpecificFields() {
      this.results = this.mappingControlFields([...this.productSpecificFields]);
    },
    changeState(field) {
      if (!this.resultNames.includes(field.name)) {
        this.results.push(field);
      } else {
        this.results = this.results.filter(el => el.name !== field.name);
      }
    },
    action(status) {
      if (status === 'hide') {
        this.results = this.allDefaultFields.filter(e => this.fieldsToHideToggle.includes(e.name));
      } else {
        this.results = this.allDefaultFields.map(field => field);
      }
    },
    closeWindow() {
      this.$emit('closeWindow');
    },
    async save() {
      const { allDefaultFieldsNames, resultNames, filterLockedFields } = this;
      const filterResultOrder = allDefaultFieldsNames.filter(name => resultNames.includes(name));
      this.$emit('update', this.resultNames, filterResultOrder, allDefaultFieldsNames, filterLockedFields);
      this.closeWindow();
    },
  },
};
</script>
<style scoped lang="scss">
.fields-control {
  display: grid;
  height: 100%;
  grid-template-rows: auto 1fr auto;
  row-gap: 1rem;

  .v-input {
    padding: 0px;
  }

  &__drag-icon {
    visibility: hidden;
    cursor: pointer;
  }

  &__draggable:hover {
    .fields-control__drag-icon:not(.fields-control__hide-icon) {
      visibility: visible;
    }
  }

  &__wrapper {
    display: grid;
    gap: 5px;
    min-height: 230px;

    overflow: auto;
    align-content: start;
    padding-top: 10px;

    .item-to-check {
      display: flex;
      font-size: 18px;
      margin-left: 20px;

      .v-input--selection-controls {
        margin: 0px;
      }
    }
  }

  .action-buttons {
    display: flex;
    gap: 10px;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    padding-top: 16px;
    flex-wrap: wrap;

    &__block {
      display: flex;
      gap: 10px;
    }

    ::v-deep.clear .v-btn__content {
      color: var(--blue-base) !important;
    }
  }
}

.lock-icon {
  padding-right: 30 / 600 * 100vw;
  justify-content: flex-end;
  display: flex;
  flex: 1;
}

.switcher.v-input--is-disabled {
  ::v-deep .v-input__control {
    .v-input__slot {
      .v-label--is-disabled {
        color: rgba(0, 0, 0, 0.6) !important;
      }
    }
  }
}
</style>
