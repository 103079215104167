<template>
  <app-dialog
    v-model="dialogForFields"
    :value.sync="dialogForFields"
    width="1124"
    :content-class="$vuetify.breakpoint.mdAndUp ? 'o-hidden v-dialog__form' : 'v-dialog__form'"
    persistent
    @close="dialogForFields = false">
    <v-card
      v-if="dialogForFields">
      <v-card-title>
        Reference Fields
        <span
          class="sourcery__icon-wrapper black"
          @click="dialogForFields = false">
          <v-icon
            color="white"
            size="15">
            mdi-close
          </v-icon>
        </span>
      </v-card-title>
      <v-card-text :class="{ 'blank-state': !productToUpdate }">
        <v-row class="h-100">
          <v-col
            md="5"
            cols="12"
            class="pb-0"
            style="height: 100%">
            <ProjectDetailControlFields
              :default-fields="items"
              :button-titles="actionButtons"
              :show-cancel="false"
              :show-save="false"
              disabled-drag-list
              one-row-action-buttons
              :product-specific-fields="productSpecificFields"
              :selected-fields="selected"
              @changeSelected="changedSelected = $event" />
          </v-col>

          <v-col
            v-if="productToUpdate"
            md="7"
            class="product-preview-wrapper pb-0"
            cols="12"
            style="height: 100%">
            <AppSearchRowsProvider
              :items="selectedSchema"
              cols="11"
              class="pb-16"
              placeholder="Search by field name"
              wrapper-class="search-component__wrapper-equal"
              search-wrapper-class="search-items__wrapper-reference"
              input-class="mb-0 pt-0">
              <template #items="{ searchItems }">
                <ProductCellFormList
                  :headers="searchItems"
                  :item="productToUpdate"
                  is-product-preview
                  view="productCreate" />
              </template>
            </AppSearchRowsProvider>
            <div
              v-if="!showSpinner"
              class="product-preview-wrapper__actions">
              <v-btn
                outlined
                class="mr-2 sub-regular"
                @click="dialogForFields = false">
                Cancel
              </v-btn>
              <v-btn
                :disabled="disabledSaveButton"
                class="white--text elevation-0 sub-regular"
                color="blue"
                @click="addToProject">
                {{ actionButtons.saveButton }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </app-dialog>
</template>
<script>
import {
  mapState, mapGetters,
} from 'vuex';

import AppSearchRowsProvider from '@/components/App/AppSearchRowsProvider';
import ProductCellFormList from '@/components/Product/ProductCell/ProductCellCollectionsFormList';
import ProjectDetailControlFields from '@/components/ProjectDetails/ProjectDetailControlFields';

import ScheduleCells from '@/services/graphql/scheduleCells';

export default {
  name: 'ProductCellFieldProductReferenceAdding',
  components: {
    AppSearchRowsProvider,
    ProductCellFormList,
    ProjectDetailControlFields,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Array,
      default: () => [],
    },
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      productSpecificFields: [],
      actionButtons: {
        saveButton: 'Add to Project',
        selectAll: 'Select All',
        deselectAll: 'Clear All',
        selectSpecificFields: 'Manufacturer Specific Fields',
      },
      changedSelected: [],
    };
  },
  computed: {
    ...mapState(['showSpinner']),
    ...mapState('Collections', ['productToUpdate']),
    ...mapGetters('Collections', ['selectedSchema']),
    disabledSaveButton() {
      return !this.changedSelected.length;
    },
    dialogForFields: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit('manageDialog', val);
      },
    },
  },
  watch: {
    dialogForFields(val) {
      if (val && !this.productSpecificFields.length) {
        this.getProductReferencePreselectedFields();
      }
    },
  },
  methods: {
    addToProject() {
      this.$emit('selectFields', this.changedSelected);
    },
    async getProductReferencePreselectedFields() {
      try {
        const { data } = await ScheduleCells.getProductReferencePreselectedFields();
        this.productSpecificFields = data.response || [];
      } catch (err) {
        console.log('getProductReferencePreselectedFields', err);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.blank-state {
  height: 100vh;
  max-height: 100%;
}
.product-preview-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  @media only screen and (max-width: 959px) {
    height: auto;
  }

  &__actions {
    position: sticky;
    bottom: 0;
    background: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  ::v-deep .item-wrapper {
    margin-bottom: 24px;
  }

  ::v-deep .search-component__wrapper-equal {
    overflow-y: scroll;
  }
}
.v-text-field {
  min-height: 90px;
  overflow: auto;
  max-height: 200px;
}
::v-deep .fields-control {
  &__wrapper {
    height: 100%;
    @media only screen and (max-width: 959px) {
      height: auto;
    }
  }
  .action-buttons {
    justify-content: flex-start;
  }
}
</style>
